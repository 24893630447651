// -----------------------------
// File: app.js
// Author: Thiago Thalison Firmino de Lima (Intelbras)
// Date: 05 jul 2020
// Brief: Guardian general policies portal main component
//
// Copyright 2020 Intelbras S.A.. All rights reserved.
// -----------------------------

import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import ContentArea from './components/contentArea';
import TopBarMenu from './components/topBarMenu';
import SideBarMenu from './components/sideBarMenu';
import contextProvider from './drivers/contextProvider';
import globalStyles from './config/globalStyles'
import lang from './config/lang.json';

export default function App() {
  const isDesktop = window.screen.width > globalStyles.DESKTOP_MIN_WIDTH;
  const [open, setOpen] = React.useState(isDesktop ? true : false);
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  const { selectedLang } = React.useContext(contextProvider.getContext());
  
  // -----------------------------
  // Set localized information on index.html
  const pageTile = document.querySelector('[data-page-title]');
  pageTile.innerHTML = lang[selectedLang]['page_title'];

  const pageDescription = document.querySelector('[data-page-description]');
  pageDescription.setAttribute("content", lang[selectedLang]['page_description']);

  // -----------------------------
  // Renders application structure
  return  <div style={{"display": "flex"}}>
    <CssBaseline />
    <TopBarMenu handleDrawerOpen={handleDrawerOpen} open={open} />
    <SideBarMenu handleDrawerClose={handleDrawerClose} open={open} />
    <ContentArea />
  </div>
}
 