// -----------------------------
// File: policiesManager.js
// Author: Thiago Thalison Firmino de Lima (Intelbras)
// Date: 05 jul 2020
// Brief: Manages the policy content being accessed
//
// Copyright 2020 Intelbras S.A.. All rights reserved.
// -----------------------------
import React from 'react';
import policies from '../config/policies.json';

// -------------------------------------------
// React context used to initialize the APP
// It provides the information needed for each component to render
var context = null;

const createPolicyInfo = (type, lang, version, isCurrent) => ({
  version: version,
  path: isCurrent ? `/${type}/${lang}/` : `/${type}/${lang}/${version}/`
})

const createContextData = (selectedType, selectedLang, selectedVersion) => {
  const terms = policies["terms"][selectedLang];
  const currentTerm = terms[0];
  const archiveTerms = terms.length > 1 ? terms.slice(1, terms.length) : null;

  const privacy = policies["privacy"][selectedLang];
  const currentPrivacy = privacy[0];
  const archivePrivacy = privacy.length > 1 ? privacy.slice(1, privacy.length) : null;

  var selectedPolicyPath = "";

  if (selectedVersion === "") {
    const currentPolicyVersion = (selectedType === "terms") ? currentTerm : currentPrivacy;
    selectedPolicyPath = `${window.location.origin}/${selectedType}/${selectedLang}/${currentPolicyVersion}.html` 
  } else {
    selectedPolicyPath = `${window.location.origin}/${selectedType}/${selectedLang}/${selectedVersion}.html` 
  }

  return {
    selectedType: selectedType,
    selectedLang: selectedLang,
    selectedVersion: selectedVersion,
    selectedPolicyPath: selectedPolicyPath,
    terms: {
      current: createPolicyInfo("terms", selectedLang, currentTerm, true),
      archive: archiveTerms ? archiveTerms.map(version => createPolicyInfo("terms", selectedLang, version, false)) : null
    },
    privacy: {
      current: createPolicyInfo("privacy", selectedLang, currentPrivacy, true),
      archive: archivePrivacy ? archivePrivacy.map(version => createPolicyInfo("privacy", selectedLang, version, false)) : null
    }
  };
}

const createContext = (selectedType, selectedLang, selectedVersion) => {
  context = React.createContext(createContextData(selectedType, selectedLang, selectedVersion));
}

const getContext = () => {
  return context;
}

export default {
  createContext,
  getContext
}