// -----------------------------
// File: policiesMenu.js
// Author: Thiago Thalison Firmino de Lima (Intelbras)
// Date: 05 jul 2020
// Brief: Presents a tree menu to access policies (privacy and terms)
//
// Copyright 2020 Intelbras S.A.. All rights reserved.
// -----------------------------

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import contextProvider from '../drivers/contextProvider'
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import lang from '../config/lang.json';
import moment from 'moment';

// -----------------------------
// Import supported locales. English is not necessary since
// it is the moment's library default
import 'moment/locale/es';
import "moment/locale/pt-br";


const styles = () => ({
  root: {
    flexGrow: 1,
    maxWidth: 400,
    color: '#5f6368',
    fontSize: '45px',
    paddingRight: '10px'
  },
  label: {
    fontSize: '14px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '15px',
  }
});

const createArchivesSection = (type, context, classes) => {
  const archive = context[type].archive;

  if (archive) {
    const archiveItems = archive.map(({version, path}) => {
      const id = `id-${type}-${version}`;
      const label = moment(version, 'YYYY_MM_DD').locale(context.selectedLang).format('LL');
      const onClick = () => window.location = path;

      return <TreeItem
                key={id}
                nodeId={id}
                label={label}
                onClick={onClick}
                classes={classes} />
    });
    
    const archivesTitle = lang[context.selectedLang]["side_bar"]["archives_title"];

    return <TreeItem nodeId={`id-${type}-archives`} label={archivesTitle} classes={classes}>
            {archiveItems}
           </TreeItem>;
  }

  return null;
}

const getDefaultTreeState = context => {
  // -----------------------------
  // selectedVersion = "" or selectedVersion = current version
  // In this case only the policy type section is expanded and
  // the current version is highlighted.
  if (context.selectedVersion === ""
      || context.selectedVersion === context[context.selectedType].current.version) {
      return {
        defaultExpanded: [`id-${context.selectedType}`],
        selected: `id-${context.selectedType}-current`
      }
  }

  // -----------------------------
  // selectedVersion != current version
  // In this case the policy type and archive sections are expanded
  // The selected version is highlighted.
  return {
    defaultExpanded: [`id-${context.selectedType}`, `id-${context.selectedType}-archives`],
    selected: `id-${context.selectedType}-${context.selectedVersion}`
  }
}

const PoliciesMenu = props => {

  const context = React.useContext(contextProvider.getContext());

  const termsSectionTitle = lang[context.selectedLang]["side_bar"]["terms_title"];
  const currentTermTitle = lang[context.selectedLang]["side_bar"]["current_term_title"];

  const privacySectionTitle = lang[context.selectedLang]["side_bar"]["privacy_title"];
  const currentPrivacyTitle = lang[context.selectedLang]["side_bar"]["current_term_title"];

  const defaultTreeState = getDefaultTreeState(context);

  return <TreeView
    className={props.classes.root}
    defaultCollapseIcon={<ExpandMoreIcon />}
    defaultExpandIcon={<ChevronRightIcon />}
    defaultExpanded={defaultTreeState.defaultExpanded}
    selected={defaultTreeState.selected}
  >
    <Divider />
    <TreeItem nodeId={`id-terms`} label={termsSectionTitle} classes={props.classes}>
      <TreeItem
        nodeId={`id-terms-current`}
        label={currentTermTitle}
        onClick={() => window.location = context.terms.current.path }
        classes={props.classes} />
      {createArchivesSection("terms", context, props.classes)}
    </TreeItem>
    <TreeItem nodeId={`id-privacy`} label={privacySectionTitle} classes={props.classes}>
      <TreeItem
          nodeId={`id-privacy-current`}
          label={currentPrivacyTitle}
          onClick={() => window.location = context.privacy.current.path }
          classes={props.classes} />
      {createArchivesSection("privacy", context, props.classes)}
    </TreeItem>
    <Divider />
  </TreeView>
}

export default withStyles(styles)(PoliciesMenu);
