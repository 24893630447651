// -----------------------------
// File: router.js
// Author: Thiago Thalison Firmino de Lima (Intelbras)
// Date: 06 jul 2020
// Brief: Manages uri path validation/routing to default location
//
// Copyright 2020 Intelbras S.A.. All rights reserved.
// -----------------------------

import policies from '../config/policies.json';

// -------------------------------------------
// Base path for policies resources: /terms/ or /privacy/
const POLICIES_URI_BASE_PATTERN = /(\/terms.*)|(\/privacy.*)/g;

const validateURI = (url) => {
  var uriData = url.match(POLICIES_URI_BASE_PATTERN);

  if (uriData) {
    uriData = uriData[0].split('/');

    const selectedType = uriData[1];
    const selectedLang = uriData[2];
    const selectedVersion = uriData[3];

    // -------------------------------------------
    // Checks whether the uri corresponds to an existing policy by inspecting the type, lang and version
    if (policies && policies[selectedType] && policies[selectedType][selectedLang]) {
      if (selectedVersion === "" || policies[selectedType][selectedLang].indexOf(selectedVersion) > -1) {
        return { selectedType, selectedLang, selectedVersion };
      }
    }

    return routeToDefaultPath(selectedType);
  }

  return routeToDefaultPath(null);
}

const routeToDefaultPath = (policyType) => {
  // -------------------------------------------
  // If policyType: routes to the policy type base path /terms or /privacy
  // If not policyType: routes to /terms
  policyType = policyType ? policyType : 'terms';
  
  // -------------------------------------------
  // Attempts to compose the URI with browsers' language when supported, otherwise defaults to english.
  let browserLang = navigator.language || navigator.userLanguage || '';
  browserLang = browserLang.toLowerCase().split('-')[0];
  let policyLang = (browserLang === 'pt') ? 'pt-br' : (browserLang === 'es') ? 'es' : 'en';

  // -------------------------------------------
  // Routes to the current version of policyType
  window.location = `/${policyType}/${policyLang}/`;
}

export default {
  validateURI
}