// -----------------------------
// File: index.js
// Author: Thiago Thalison Firmino de Lima (Intelbras)
// Date: 05 jul 2020
// Brief: Guardian general policies portal index script file
//
// Copyright 2020 Intelbras S.A.. All rights reserved.
// -----------------------------

import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';
import contextProvider from './drivers/contextProvider';
import router from './drivers/router';

// -------------------------------------------
// Evaluates whether the URI corresponds to a valid policy
// In case the URI is invalid, it routes to a default valid URI
var { selectedType, selectedLang, selectedVersion } = router.validateURI(window.location.pathname);

// -------------------------------------------
// Creates the context in which the application must render according with the selected policy
contextProvider.createContext(selectedType, selectedLang, selectedVersion);

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
