// -----------------------------
// File: globalStyles.js
// Author: Thiago Thalison Firmino de Lima (Intelbras)
// Date: 05 jul 2020
// Brief: CSS rules to be shared among components
// -----------------------------

export default {
  sideBarMenuWidth: 240,
  DESKTOP_MIN_WIDTH: 1024
}
