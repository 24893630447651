// -----------------------------
// File: topBarMenu.js
// Author: Thiago Thalison Firmino de Lima (Intelbras)
// Date: 05 jul 2020
// Brief: Presents the top bar menu area
//
// Copyright 2020 Intelbras S.A.. All rights reserved.
// -----------------------------

import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import contextProvider from '../drivers/contextProvider'
import lang from '../config/lang.json'
import globalStyles from '../config/globalStyles'

const styles = theme => ({
  privacyIcon : {
    flexGrow: 0.01,
    width: "26px",
    paddingRight: "10px",
    [theme.breakpoints.down('lg')]: {
      width: "28px",
    },
    [theme.breakpoints.down('sm')]: {
      width: "32px",
    },
    [theme.breakpoints.down('xs')]: {
      width: "35px",
      marginBottom: "2px",
    },
  },
  title: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4em',
    }
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    color: '#00a335',
    overflow: 'visible',
  },
  menuButton: {
    marginRight: 12,
    justifyContent: 'left'
  },
  menuButtonHidden: {
    display: 'none',
  },
  titleHeaderHidden: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  gridList: {
    padding: 'inherit',
  },
  gridTile: {
    height: "100% !important",
    width: "auto !important",
    marginLeft: "5px",
    marginRight: "5px",
    color: "#5f6368",
  },
  appBar: {
    background: '#ffffff',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    background: '#ffffff',
    marginLeft: globalStyles.sideBarMenuWidth,
    width: `calc(100% - ${globalStyles.sideBarMenuWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }
});

const TopBarMenu = props => {
  const { selectedLang } = React.useContext(contextProvider.getContext())
  const appBarClass = clsx(props.classes.appBar, props.open && props.classes.appBarShift)
  const iconButtonClass = clsx(props.classes.menuButton, props.open && props.classes.menuButtonHidden)
  const hideTitle = clsx(props.classes.title, props.open && props.classes.titleHeaderHidden)
  return <AppBar position="absolute" className={appBarClass}>
          <Toolbar className={props.classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={props.handleDrawerOpen}
              className={iconButtonClass}>
              <MenuIcon />
            </IconButton>
            <img src="/img-icons/iconPrivacyHeader.png" alt="privacy icon" className={props.classes.privacyIcon}></img>
            <Typography display='inline' variant="h6" className={hideTitle}>{lang[selectedLang]["top_bar"]["title"]}</Typography>
          </Toolbar>
        </AppBar>
} 

export default withStyles(styles)(TopBarMenu);
