// -----------------------------
// File: sideBarMenu.js
// Author: Thiago Thalison Firmino de Lima (Intelbras)
// Date: 05 jul 2020
// Brief: Presents the side bar menu area with the policies menu
//
// Copyright 2020 Intelbras S.A.. All rights reserved.
// -----------------------------

import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import globalStyles from '../config/globalStyles'
import PoliciesMenu from './policiesMenu'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: globalStyles.sideBarMenuWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: '0px',
  },
  intelbrasLogo: {
    flexGrow: 0.01,
    width: "55%",
    marginRight: "17%"
  }
});

const SideBarMenu = props => {
  const drawerClasses = {
    paper: clsx(props.classes.drawerPaper, !props.open && props.classes.drawerPaperClose)
  }

  return <Drawer
            variant="permanent"
            classes={drawerClasses}
            open={props.open} >
              <div className={props.classes.root}>
                <img src="/img-icons/intelbras_logo.png" alt="intelbras logo" className={props.classes.intelbrasLogo}></img>
                <IconButton onClick={props.handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              </div>
            <PoliciesMenu />
          </Drawer>
}

export default withStyles(styles)(SideBarMenu);
