// -----------------------------
// File: contentArea.js
// Author: Thiago Thalison Firmino de Lima (Intelbras)
// Date: 05 jul 2020
// Brief: Presents the selected policy content
//
// Copyright 2020 Intelbras S.A.. All rights reserved.
// -----------------------------

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import contextProvider from '../drivers/contextProvider';
import lang from '../config/lang.json';

const setHeight = (event) => {
  const iframe = event.target;
  iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
  iframe.contentWindow.document.body.style.overflow = 'hidden';

  const iframeParent = event.target.parentNode;
  iframeParent.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
}

const styles = theme => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    backgroundColor: '#ffffff'
  },
  iframe: {
    overflow: 'hidden',
    width: "100%",
    backgroundColor: '#ffffff',
    border: '0px'
  },
  appBarSpacer: theme.mixins.toolbar
});

const ContentArea = props => {

  const { selectedPolicyPath } = React.useContext(contextProvider.getContext());
  
  return  <main className={props.classes.content} data-policy-content>
            <div className={props.classes.appBarSpacer} />
            <iframe
              src={selectedPolicyPath}
              title={lang['company_name']}
              width="100%"
              onLoad={setHeight}
              className={props.classes.iframe} />
          </main>
}

export default withStyles(styles)(ContentArea);
 